<template>
  <div>
    <rs-button type="text" icon="rs-icon-plus" @click="handleRole" size="small" class="add-role-button">添加角色</rs-button>
    <rs-table :data="rolesTableData" highlight-current-row class="my-table-auth" tooltip-effect="light" border style="width: 100%;">
      <rs-table-column label="角色名称" align="left" show-overflow-tooltip>
        <template slot-scope="scope">
          <span>{{ scope.row.roleName }}</span>
        </template>
      </rs-table-column>
      <rs-table-column label="授权时间" prop="createTime" show-overflow-tooltip align="left"></rs-table-column>
      <rs-table-column label="操作" width="200" align="left">
        <template slot-scope="scope">
          <rs-button @click="remove(scope.row)" type="text" size="small">移除</rs-button>
        </template>
      </rs-table-column>
    </rs-table>
  </div>
</template>

<script>
import userApi from '@/api/account'
export default {
  data() {
    return {
      userId: '',
      // 角色表单
      roleForm: {
        roles: ''
      },
      roleRules: {},
      rolesTableData: []
    }
  },
  methods: {
    // 移除角色权限
    remove(row) {
      let params = {
        roleId: row.roleId,
        userId: this.userId
      }
      userApi.setUnbindRole(params).then(res => {
        this.$message({
          message: '操作成功！',
          type: 'success'
        })
        this.handleRoles()
      })
    },
    // 获取用户角色权限
    handleRoles() {
      let that = this
      userApi.queryRolesAuth(this.userId).then(res => {
        this.rolesTableData = res.data.data
      })
    },
    // 添加角色权限
    handleRole() {
      this.roleForm.roles = ''
      top
        .$dialogManage({
          width: '543px',
          title: '添加角色',
          formData: this.roleForm,
          rules: this.roleRules,
          type: 'roleBindForm',
          className: 'form-dialog add-form-dialog',
          btnText: '确定授权',
          top:0
        })
        .then(data => {
          let form = data
          // form.parentOrgSysCode = data.parentOrgSysCode[0]
          this.submitRoles(form)
        })
    },
    // dialog事件
    submitRoles(data) {
      let params = {
        roleId: data.roles == '' ? null : data.roles,
        userId: this.userId
      }
      userApi
        .setBindRole(params)
        .then(res => {
          this.$message({
            message: '操作成功！',
            type: 'success'
          })
          this.handleRoles()
        })
        .catch(err => {
          // this.$message({
          //     message: '操作成功！',
          //     type: 'error'
          // });
        })
    }
  },
  //生命周期 - 创建完成（访问当前this实例）
  created() {
    this.userId = this.$route.query.id
    this.handleRoles()
  },
  //生命周期 - 挂载完成（访问DOM元素）
  mounted() {}
}
</script>
<style scoped>
/* @import url(); 引入css类 */
.add-role-button {
  padding: 0 !important;
  margin-bottom: 10px;
  height: 20px !important;
}
</style>
