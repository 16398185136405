<template>
    <div class="height100">
        <header-title :title="title"></header-title>
        <rs-row class="height100">
            <rs-col :span="24" class="box box-r">
                <div class="grid-content bg-purple-light right">
                    <rs-tabs v-model="authTab" @tab-click="handleChangeTab" type="card">
                        <rs-tab-pane label="基本信息" name="jibenxinxi" v-if="viewType!='auth'">
                            <div class="grid-content bg-purple">
                                <div class="edit-button">
                                    <rs-button size="mini" class="mini-button-click" type="primary" @click="editUser">编辑</rs-button>
                                </div>
                                <rs-form :model="accountForm" label-width="156px" style="padding-right: 20px;" size="small" label-position="right" status-icon ref="accountForm" class="form-70 demo-ruleForm">
                                    <rs-col :span="12">
                                        <rs-form-item label="账户ID:" >
                                            {{accountForm.userCode}}
                                        </rs-form-item>
                                    </rs-col>
                                    <rs-col :span="12">
                                        <rs-form-item label="员工姓名:" >
                                            {{accountForm.userName}}
                                        </rs-form-item>
                                    </rs-col>
                                    <rs-col :span="12">
                                        <rs-form-item label="登录名:" >
                                            {{accountForm.loginName}}
                                        </rs-form-item>
                                    </rs-col>
                                    <rs-col :span="12">
                                        <rs-form-item label="所在组织:" >
                                            {{accountForm.orgName}}
                                        </rs-form-item>
                                    </rs-col>
                                    <rs-col :span="12">
                                        <rs-form-item label="岗位名称:">
                                            {{accountForm.stationName}}
                                        </rs-form-item>
                                    </rs-col>
                                    <rs-col :span="12">
                                        <rs-form-item label="性别:">
                                            {{accountForm.sex==1?'女':accountForm.sex==0?'男':''}}
                                        </rs-form-item>
                                    </rs-col>
                                    <rs-col :span="12">
                                        <rs-form-item label="手机:">
                                            {{accountForm.mobile}}
                                        </rs-form-item>
                                    </rs-col>
                                    <rs-col :span="12">
                                        <rs-form-item label="邮箱:">
                                            {{accountForm.email}}
                                        </rs-form-item>
                                    </rs-col>
                                    <rs-col :span="12">
                                        <rs-form-item label="创建人:">
                                            {{accountForm.createName}}
                                        </rs-form-item>
                                    </rs-col>
                                    <rs-col :span="12">
                                        <rs-form-item label="创建时间:">
                                            {{accountForm.createTime}}
                                        </rs-form-item>
                                    </rs-col>
                                    <rs-col :span="12">
                                        <rs-form-item label="更新人:">
                                            {{accountForm.modifyName}}
                                        </rs-form-item>
                                    </rs-col>
                                    <rs-col :span="12">
                                        <rs-form-item label="更新时间:">
                                            {{accountForm.modifyTime}}
                                        </rs-form-item>
                                    </rs-col>
                                </rs-form>
                            </div>
                        </rs-tab-pane>
                        <rs-tab-pane label="数据权限" name="shujuquanxian">
                            <rs-table
                                :data="tableData"
                                highlight-current-row
                                border
                                class="my-table-auth drawer-table"
                                style="width: 70%;">
                                <rs-table-column
                                    label="项目名称"
                                    prop="mallName"
                                    align="left"
                                    >
                                </rs-table-column>
                                <rs-table-column
                                    label="是否授权"
                                    width="150"
                                    align="left">
                                    <template slot-scope="scope">
                                        <rs-switch
                                            @change="handleChangeAuth(scope.row)"
                                            v-model="scope.row.empower">
                                        </rs-switch>
                                    </template>
                                </rs-table-column>
                            </rs-table>
                            <auth-tree :userId="userId"></auth-tree>
                        </rs-tab-pane>
                        <rs-tab-pane label="功能权限" name="gongnengquanxian">
                            <rs-radio-group v-model="type" style="margin: 11px 0 20px 0;" size="small" @change="changeType">
                                <rs-radio-button label="auth">已分配功能权限</rs-radio-button>
                                <rs-radio-button label="role">已分配角色</rs-radio-button>
                            </rs-radio-group>
                            <div v-if="type=='auth'">
                                <rs-button type="text" class="add-role-button" @click="addUserAuth" size="small">分配权限</rs-button>
                                <auth style="max-width: 70%;" ref="authDetail" :type="'user'"></auth>
                            </div>
                            <role-bind-table v-if="type=='role'"></role-bind-table>
                        </rs-tab-pane>
                    </rs-tabs>
                </div>
            </rs-col>
        </rs-row>

    </div>
</template>

<script>
    import utils from '@/utils/utils'
    import userApi from '@/api/account'
    import roleBindTable from './components/roleBindTable'
    import auth from '../authDetail'
    import moment from 'moment'
    import headerTitle from '@/components/header'
    import authTree from './components/authTree'
    export default {
        components: {
            roleBindTable,
            auth,
            headerTitle,
            authTree
        },
        data(){
            return{
                title: '账户详情',
                accountForm: {},
                form: {},
                loading: false, // 下拉框搜索加载
                readonly: true, // 只读模式和编辑模式
                tableData: [],
                rolesTableData: [],
                rolesList: [], // 角色列表
                roleShow: false, // 添加角色flag
                userId: '',
                showAccount: false,
                // tab绑定值
                authTab: 'jibenxinxi',
                selectMall: {},
                type: 'auth',
                viewType: ''
            }
        },
        methods:{
            // 商场默认选中切换当前选中商场
            // 查询该系统下所有商场
            queryMalls() {
                let self = this
                let userInfo = Utils.getSession("userInfo");
                let zendaiMarket = Utils.getSession("zendaiMarket")?Utils.getSession("zendaiMarket"):userInfo.mallVo
                Utils.setSession('zendaiMarket', zendaiMarket)
                this.mainEntSelectVal = Utils.getSession("zendaiMarket")?Utils.getSession("zendaiMarket").id:''
                userApi.queryHomeAuthMall(userInfo.id).then(res=>{
                    if(res.data.data&&res.data.data.length>0) {
                        self.selectMall = res.data.data.find(item=>{
                            return item.selected
                        })
                        if(self.selectMall) {
                            self.handleChangeMall(self.selectMall.id)
                        } 
                    } else {
                        Utils.setSession('zendaiMarket', null)
                        userInfo.mallVo = null
                        Utils.setSession('userInfo', userInfo)
                    }
                })
            },
            // 选择商场,针对该商场下的数据
            handleChangeMall(val) {
                let user = Utils.getSession('userInfo')
                indexApi.checkMarket({mallId: val, userId: user.id}).then(res=>{
                    let checkedMall = this.selectMall
                    Utils.setSession('zendaiMarket', checkedMall)
                    user.mallVo = checkedMall
                    Utils.setSession('userInfo', user)
                })
            },
            // 添加权限
            addUserAuth() {
                let form = {
                    operationId: this.userId,
                    operationType: 0,
                    applicationPrivilegeVoList: {}
                }
                top.$dialogManage({
                    width: '498px',
                    title: '分配权限',
                    formData: form,
                    type: 'addUserAuth',
                    className: 'form-dialog add-form-dialog'
                }).then(data => {
                    let form = data.applicationPrivilegeVoList
                    this.submitAuth(form)
                })
            },
            submitAuth(params) {
                userApi.setAuth(params).then(res=>{
                    top.$message.success('设置成功！')
                    this.handleDetail()
                    this.$refs['authDetail'].handleAuthData()
                }).catch(err=>{})
            },
            // 获取详情
            handleDetail() {
                userApi.getUserInfoDetail({id: this.userId}).then(res=>{
                    this.accountForm = res.data.data
                })
            },
            // 获取用户数据权限
            handleMalls() {
                userApi.queryMallsAuth(this.userId).then(res=>{
                    this.tableData = res.data.data
                })
            },
            handleChangeTab(tab, event) {},
            changeType(val) {},
            // 操作数据权限授权和取消授权
            handleChangeAuth(val) {
                let params = [{
                    masterId: this.accountForm.id,
                    accessId: val.id,
                    accessType: 2
                }]
                if(val.empower) {
                    params[0].operationType = 2
                    let form = {
                        "privilegeVoList": params,
                        "operationId": this.accountForm.id,
                        "operationType": 0
                    }
                    userApi.addAuth(form).then(res=>{
                        this.$message({
                            message: '操作成功！',
                            type: 'success'
                        });
                        this.handleMalls()
                    }).catch(err=>{
                        this.handleMalls()
                    })
                } else {
                    params[0].operationType = 3
                    userApi.cancelAuth(params[0]).then(res=>{
                        this.$message({
                            message: '操作成功！',
                            type: 'success'
                        });
                        this.handleMalls()
                        this.queryMalls()
                    }).catch(err=>{
                        this.handleMalls()
                    })
                }
            },
            // 提交编辑user
            editUser() {
                this.$router.push(`/accountManageEdit?id=${this.userId}&orgName=${this.accountForm.orgName}`)
            },
        },
        created(){
            this.userId = this.$route.query.id
            this.viewType = this.$route.params.type
            if(this.viewType==='auth') this.authTab = 'shujuquanxian'
            this.handleDetail()
            this.handleMalls()
        },
        filters:{
            sex(item) {
                return item == 1?'男':'女'
            },
            status(val) {
                return val == 1?'启用':'冻结'
            }
        },
    }
</script>

<style scoped>
.height100 {
    height: 100%;
}
.box {
    background: #FFFFFF;
    height: calc(100% - 19px);
    /* padding: 10px 20px; */
}
.box-l {
    border: 1px solid rgba(0,0,0,0.1);
    margin: 4px 1.3% 15px 1.3%;
}
.box-r {
    margin-bottom: 15px;
    overflow: auto;
    padding-bottom: 15px;
    /* border-left: 1px solid rgba(0,0,0,0.1); */
}
.edit-button {
    display: flex;
    justify-content: flex-end;
    padding: 3px 9px;
}
/deep/.right .rs-tabs__header {
    margin-bottom: 9px;
}
.hover-style {
    font-size: 14px;
}
.rs-form-item{
    margin-bottom:0;
}
/deep/.rs-form-item__label {
    color: #666666;
}
.right {
    position: relative;   
    padding: 0 9px 0 13px;
}
.right .view-auth-button {
    position: absolute;
    top: 0;
    right: 9px;
}
.box-l .rs-form-item__content .rs-button--mini {
    padding: 3px 0;
}
/deep/.station-button-list .rs-form-item__content{
    line-height: 22px;
    margin-top: 9px;
}
/deep/.station-button-list button.rs-button.hover-style.rs-button--text.rs-button--mini {
    margin-left: 0!important;
    margin-right: 20px!important;
}
/deep/.rs-tabs__content {
    overflow: visible;
}
.header {
    margin-bottom: 9px!important;
}
.add-role-button {
    padding: 0!important;
    margin-bottom: 10px;
    height: 20px!important;
}
</style>
