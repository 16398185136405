var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "rs-button",
        {
          staticClass: "add-role-button",
          attrs: { type: "text", icon: "rs-icon-plus", size: "small" },
          on: { click: _vm.handleRole },
        },
        [_vm._v("添加角色")]
      ),
      _c(
        "rs-table",
        {
          staticClass: "my-table-auth",
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.rolesTableData,
            "highlight-current-row": "",
            "tooltip-effect": "light",
            border: "",
          },
        },
        [
          _c("rs-table-column", {
            attrs: {
              label: "角色名称",
              align: "left",
              "show-overflow-tooltip": "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.roleName))])]
                },
              },
            ]),
          }),
          _c("rs-table-column", {
            attrs: {
              label: "授权时间",
              prop: "createTime",
              "show-overflow-tooltip": "",
              align: "left",
            },
          }),
          _c("rs-table-column", {
            attrs: { label: "操作", width: "200", align: "left" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "rs-button",
                      {
                        attrs: { type: "text", size: "small" },
                        on: {
                          click: function ($event) {
                            return _vm.remove(scope.row)
                          },
                        },
                      },
                      [_vm._v("移除")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }