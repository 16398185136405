var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "authTree" },
    [
      _c(
        "rs-row",
        [
          _c(
            "rs-col",
            { staticClass: "org-top" },
            [
              _c("p", [_vm._v("组织架构")]),
              _c(
                "rs-checkbox",
                {
                  model: {
                    value: _vm.openOrgnPrivilege,
                    callback: function ($$v) {
                      _vm.openOrgnPrivilege = $$v
                    },
                    expression: "openOrgnPrivilege",
                  },
                },
                [_vm._v("是否启用")]
              ),
            ],
            1
          ),
          _c(
            "rs-col",
            { staticStyle: { "margin-top": "20px" } },
            [
              _c(
                "rs-checkbox-group",
                {
                  staticStyle: { "padding-left": "24px" },
                  model: {
                    value: _vm.checkedUser,
                    callback: function ($$v) {
                      _vm.checkedUser = $$v
                    },
                    expression: "checkedUser",
                  },
                },
                _vm._l(_vm.userList, function (item, index) {
                  return _c(
                    "rs-checkbox",
                    { key: index, attrs: { label: item.id } },
                    [_vm._v("自己")]
                  )
                }),
                1
              ),
              _c("rs-tree", {
                ref: "tree",
                attrs: {
                  data: _vm.treeData,
                  "show-checkbox": "",
                  "check-strictly": true,
                  "node-key": "id",
                  props: _vm.defaultProps,
                  "default-expanded-keys": _vm.expandedArr,
                  "default-checked-keys": _vm.defaultArr,
                },
                on: { check: _vm.getCheck },
              }),
            ],
            1
          ),
          _c(
            "rs-col",
            { staticStyle: { "text-align": "right", padding: "20px" } },
            [
              _c(
                "rs-button",
                {
                  attrs: { type: "primary", size: "small" },
                  on: { click: _vm.saveTree },
                },
                [_vm._v("保存")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }