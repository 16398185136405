<template>
  <div class="authTree">
    <rs-row>
      <rs-col class="org-top">
        <p>组织架构</p>
        <rs-checkbox v-model="openOrgnPrivilege">是否启用</rs-checkbox>
      </rs-col>
      <rs-col style="margin-top: 20px;">
        <rs-checkbox-group v-model="checkedUser" style="padding-left: 24px;">
          <rs-checkbox v-for="(item, index) in userList" :label="item.id" :key="index">自己</rs-checkbox>
        </rs-checkbox-group>
        <rs-tree
          :data="treeData"
          show-checkbox
          :check-strictly="true"
          node-key="id"
          :props="defaultProps"
          :default-expanded-keys="expandedArr"
          :default-checked-keys="defaultArr"
          @check="getCheck"
          ref="tree"
        ></rs-tree>
      </rs-col>
      <rs-col style="text-align: right; padding: 20px; ">
        <rs-button type="primary" size="small" @click="saveTree">保存</rs-button>
      </rs-col>
    </rs-row>
  </div>
</template>

<script>
import Api from '@/api/organizationApi'
import userApi from '@/api/account'
import utils from '../../../../utils/utils'

export default {
  name: 'authTree',
  props: {
    userId: {
      type: [String, Number]
    }
  },
  data() {
    return {
      defaultProps: {
        label: 'orgName',
        children: 'children'
      },
      treeData: [],
      expandedArr: [31],
      defaultArr: [],
      ismodifyed: false,
      stationList: [],
      openOrgnPrivilege: false,
      checkedUser: [],
      userList: []
    }
  },
  created() {
    this.userList = [
      {
        id: Number(this.userId),
        accessId: this.userId,
        accessType: 4,
        masterId: this.userId,
        masterType: 1,
        operationType: 2,
        name: '自己'
      }
    ]
    this.getUserPrivilegeAndOpenOrgan(this.userId)
    this.queryOrganizationTree()
  },
  methods: {
    // 获取树结构
    queryOrganizationTree() {
      Api.queryOrganizationTree().then(res => {
        // console.log(res.data);
        this.treeData = res.data.data
        this.deepTreeData(this.treeData)
        // console.log(this.treeData)
      })
    },
    // 遍历树形结构
    deepTreeData(data) {
      let _this = this
      function deepData(data) {
        data.forEach(item => {
          item.masterId = _this.userId
          item.masterType = 1
          item.operationType = 2
          item.accessType = 3
          item.accessId = item.id
          if (item.children && item.children.length > 0) {
            deepData(item.children)
          }
        })
      }
      deepData(data)
    },
    getCheck(data, data1) {
      // console.log(data);
      // console.log(data1);
      this.stationList = data1.checkedNodes
    },
    // 保存组织架构授权信息
    saveTree() {
      // console.log(this.checkedUser)
      // console.log(this.$refs.tree.getCheckedNodes());
      let params = {
        openOrgnPrivilege: this.openOrgnPrivilege,
        userCode: utils.getSession('userInfo').userCode,
        userName: utils.getSession('userInfo').userName,
        masterType: 1,
        masterId: this.userId,
        organPrivilegeVoList: this.$refs.tree.getCheckedNodes(),
        selfPrivilegeVoList: this.checkedUser.length > 0 ? this.userList : []
      }
      userApi.addPrivilegeCommon(params).then(res => {
        this.$message.success('保存成功')
      })
    },
    // 获取已授权过的组织架构
    getUserPrivilegeAndOpenOrgan(id) {
      userApi.getUserPrivilegeAndOpenOrgan({ userId: id }).then(res => {
        let data = res.data
        this.expandedArr = data.data.privilegeOrganIds || []
        this.defaultArr = data.data.privilegeOrganIds || []
        this.checkedUser = data.data.privilegeSelfIds || []
        this.openOrgnPrivilege = data.data.openOrgnPrivilege
      })
    }
  }
}
</script>

<style lang="less" scoped>
.authTree {
  border: 1px solid #ebeef5;
  width: 70%;
  margin-top: 20px;
  .org-top {
    display: flex;
    justify-content: space-between;
    height: 40px;
    background: #dedede;
    align-items: center;
    padding: 0 20px;
  }
}
</style>
